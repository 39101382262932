import React, { useState, useEffect } from 'react'
import {
  Box,
  Button,
  TextField,
  IconButton,
  Autocomplete,
  Grid
} from '@mui/material'
import styled from '@emotion/styled'
import { Breadcrumb } from 'app/components'
import SearchIcon from '@mui/icons-material/Search'
import axiosInstance from 'axiosInstance'
import { HandleError } from 'app/utils/handleError'
import { useNavigate } from 'react-router-dom'
import { useSnackbar } from 'app/snakebarContext'
import SalesForm from 'app/components/Sales/SalesForm'
import SaleTable from 'app/components/Sales/SalesTable'
import { DesktopDatePicker, LocalizationProvider } from '@mui/x-date-pickers'

import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import SalesDetailsModel from 'app/components/Sales/SalesDetailsModel'
import useAuth from 'app/hooks/useAuth'

const Container = styled('div')(({ theme }) => ({
  margin: '30px',
  [theme.breakpoints.down('sm')]: { margin: '16px' },
  '& .breadcrumb': {
    marginBottom: '30px',
    [theme.breakpoints.down('sm')]: { marginBottom: '16px' }
  }
}))

const dropDownOptions = {
  sourceDropdownOptions: [
    'IndiaMART',
    'TradeIndia',
    'Facebook',
    'Whatsapp',
    'Instagram',
    'References'
  ],
  unitDropdownOptions: ['KL', 'MTS'],
  paymentDropdownOptions: ['Paid', 'Unpaid']
}

const SalesList = () => {
  const [open, setOpen] = useState(false)
  const [detailsModalOpen, setDetailsModalOpen] = useState(false)
  const [detailsModalData, setDetailsModalData] = useState(null)
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(5)
  const [allSalesData, setAllSalesData] = useState([])
  const [allClients, setAllClients] = useState([])
  const [allProducts, setAllProducts] = useState([])
  const [allCompanyName, setAllCompanyName] = useState([])
  const [allRole, setAllRole] = useState([])
  const [totalSales, setTotalSales] = useState(0)
  const [initialSale, setInitialSale] = useState(null)
  const [editingSale, setEditingSale] = useState(null)
  const [submitting, setSubmitting] = useState(false)
  const [loading, setLoading] = useState(false)
  const { hasPermission } = useAuth()
  const { showSnackbar } = useSnackbar()
  const navigate = useNavigate()
  const [filter, setFilter] = useState({
    customerName: null,
    date: null,
    companyName: null,
    product: null,
    addedBy: null
  })

  const fetchAllRoles = async () => {
    try {
      const response = await axiosInstance.get('/api/roles')
      if (response.data && Array.isArray(response.data)) {
        const roleArr = response.data.map((role) => ({
          value: role?.name,
          id: role?._id
        }))
        setAllRole(roleArr)
      }
    } catch (error) {
      HandleError(error, showSnackbar, navigate)
    }
  }

  const fetchAllCompanyName = async () => {
    try {
      const response = await axiosInstance.get('/api/sales/lookup-company-name')
      const data = response.data || []
      setAllCompanyName(data)
    } catch (error) {
      HandleError(error, showSnackbar, navigate)
      console.error('Error fetching data:', error)
    }
  }

  const getAllCustomerName = async () => {
    try {
      const response = await axiosInstance.get('/api/clients/lookUps')
      const data = response.data?.clients || []
      setAllClients(data)
    } catch (error) {
      HandleError(error, showSnackbar, navigate)
      console.error('Error fetching data:', error)
    }
  }

  const getAllProducts = async (page, rowsPerPage) => {
    try {
      const response = await axiosInstance.get('/api/items/lookUps')
      const data = response.data?.items || []
      setAllProducts(data)
    } catch (error) {
      HandleError(error, showSnackbar, navigate)
      console.error('Error fetching data:', error)
    }
  }

  const getAllSalesData = async (page, rowsPerPage, filters) => {
    try {
      setLoading(true)
      const response = await axiosInstance.get('/api/sales', {
        params: {
          page,
          rowsPerPage,
          customerName: filters?.customerName?.key,
          product: filters?.product?.key,
          date: filters?.date,
          companyName: filters?.companyName,
          addedBy: filters?.addedBy
        }
      })

      setAllSalesData(() => response.data?.sales || [])
      setTotalSales(() => response.data?.totalSales)
    } catch (error) {
      HandleError(error, showSnackbar, navigate)
    } finally {
      setLoading(false)
    }
  }

  const handleClickOpen = (saleId = null, sale = null) => {
    setOpen(true)
  }

  const handleClose = () => {
    setOpen(false)
    setEditingSale(null)
    setInitialSale(null)
    setSubmitting(false)
  }

  const handleViewClick = (sale) => {
    setDetailsModalOpen(true)
    setDetailsModalData(sale)
  }
  const handleViewClose = () => {
    setDetailsModalOpen(false)
    setDetailsModalData(null)
  }
  const handleEdit = async (sale) => {
    setOpen(true)
    setInitialSale(sale)
    setEditingSale(sale?._id)
  }

  const handleAddSale = async (sale) => {
    const result = await axiosInstance.post('/api/sales', {
      ...sale,
      density: Number(sale?.density) / 1000,
      day: sale?.date?.$D,
      month: sale?.date?.$M + 1,
      year: sale?.date?.$y
    })
    if (result.status === 201) {
      showSnackbar({
        message: 'Sale data added successfully!',
        severity: 'success',
        vertical: 'top',
        horizontal: 'right'
      })
      await fetchAllCompanyName() // fetch company name if new added.
      await getAllSalesData(page + 1, rowsPerPage, filter)
    }
  }

  const handleUpdateSale = async (sale) => {
    const result = await axiosInstance.put(`/api/sales/${editingSale}`, {
      ...sale,
      density: Number(sale?.density) / 1000,
      day: sale?.date.$D,
      month: sale?.date?.$M + 1,
      year: sale?.date?.$y
    })
    if (result.status === 200) {
      showSnackbar({
        message: 'Sale data updated successfully!',
        severity: 'success',
        vertical: 'top',
        horizontal: 'right'
      })
      await fetchAllCompanyName()
      await getAllSalesData(page + 1, rowsPerPage, filter)
    }
  }

  const handleDeleteSale = async (id) => {
    const result = await axiosInstance.delete(`/api/sales/${id}`)
    if (result.status === 200) {
      showSnackbar({
        message: 'Sale data deleted successfully!',
        severity: 'success',
        vertical: 'top',
        horizontal: 'right'
      })
      await getAllSalesData(page + 1, rowsPerPage, filter)
    }
  }

  const handleUpdateSaleField = async (value, sale, field) => {
    try {
      setSubmitting(true)

      const result = await axiosInstance.patch(
        `/api/sales/update-field/${sale?._id}`,
        { field, value }
      )
      if (result.status === 200) {
        showSnackbar({
          message: `${field?.charAt(0).toUpperCase() + field.slice(1)} field updated successfully!`,
          severity: 'success',
          vertical: 'top',
          horizontal: 'right'
        })
        await getAllSalesData(page + 1, rowsPerPage, filter)
      }
    } catch (error) {
      HandleError(error, showSnackbar, navigate)
      console.error('Error updating sale:', error)
    } finally {
      setSubmitting(false)
    }
  }
  const handleSearch = async () => {
    setPage(0)
    await getAllSalesData(1, rowsPerPage, filter)
  }
  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(+event.target.value)
    setPage(0) // Reset page to 0 when rows per page changes
  }

  const handleChangePage = (_, newPage) => {
    setPage(newPage)
  }

  useEffect(() => {
    fetchAllRoles()
    fetchAllCompanyName()
    getAllCustomerName()
    getAllProducts()
  }, [])

  useEffect(() => {
    getAllSalesData(page + 1, rowsPerPage, filter)
  }, [page, rowsPerPage])

  return (
    // <Container>
    //   <Box className="breadcrumb">
    //     <Breadcrumb routeSegments={[{ name: 'Sales' }]} />
    //   </Box>
    //   <Grid
    //     container
    //     alignItems="center"
    //     justifyContent="space-between"
    //     spacing={2}
    //   >
    //     {/* Left-aligned button */}
    //     {hasPermission('Sales.Orders:add') && (
    //       <Grid item xs={12} md={1} lg={2} xl={3}>
    //         <Button
    //           variant="contained"
    //           color="primary"
    //           onClick={() => handleClickOpen()}
    //           disabled={submitting}
    //         >
    //           + Add Sales
    //         </Button>
    //       </Grid>
    //     )}

    //     {/* Right-aligned filters */}
    //     <Grid item xs={12} sm={12} md={11} lg={10} xl={9}>
    //       <Box
    //         display="flex"
    //         alignItems="center"
    //         gap={2}
    //         flexDirection={{ xs: 'column', md: 'row' }}
    //         sx={{
    //           width: '100%',
    //           maxWidth: '1000px',
    //           margin: '0 auto'
    //         }}
    //       >
    //         <Autocomplete
    //           options={allClients}
    //           value={filter.customerName?.value}
    //           onChange={(event, newValue) =>
    //             setFilter((prev) => ({ ...prev, customerName: newValue }))
    //           }
    //           getOptionLabel={(option) => option?.value || ''}
    //           renderInput={(params) => (
    //             <TextField
    //               {...params}
    //               label="Name"
    //               variant="outlined"
    //               fullWidth
    //               sx={{
    //                 flexGrow: 1,
    //                 minWidth: '150px',
    //                 width: { xs: '100%', sm: '100%' },
    //                 height: 38,
    //                 '.MuiAutocomplete-input': {
    //                   height: 8,
    //                   overflow: 'auto'
    //                 },
    //                 '.MuiAutocomplete-tag': {
    //                   margin: '2px'
    //                 }
    //               }}
    //               InputProps={{
    //                 ...params.InputProps,
    //                 sx: { height: '40px' }
    //               }}
    //               InputLabelProps={{
    //                 sx: { top: -6 }
    //               }}
    //             />
    //           )}
    //           sx={{
    //             flexGrow: 1,
    //             minWidth: '170px',
    //             width: { xs: '100%', sm: '100%' }
    //           }}
    //         />

    //         <LocalizationProvider dateAdapter={AdapterDayjs}>
    //           <DesktopDatePicker
    //             onChange={(date) =>
    //               setFilter((prev) => ({ ...prev, date: date }))
    //             }
    //             slotProps={{
    //               field: {
    //                 clearable: true,
    //                 onClear: () =>
    //                   setFilter((prev) => ({ ...prev, date: null }))
    //               },
    //               textField: {
    //                 sx: {
    //                   flexGrow: 1,
    //                   minWidth: '160px',

    //                   width: { xs: '100%', sm: '100%' },
    //                   '.css-14rz9al-MuiInputBase-root-MuiOutlinedInput-root': {
    //                     height: '40.5px',
    //                     overflow: 'hide'
    //                   }
    //                 }
    //               }
    //             }}
    //           />
    //         </LocalizationProvider>
    //         <Autocomplete
    //           options={allCompanyName}
    //           value={filter?.companyName}
    //           onChange={(event, newValue) =>
    //             setFilter((prev) => ({ ...prev, companyName: newValue }))
    //           }
    //           renderInput={(params) => (
    //             <TextField
    //               {...params}
    //               label="Company Name"
    //               variant="outlined"
    //               fullWidth
    //               sx={{
    //                 flexGrow: 1,
    //                 minWidth: '150px',
    //                 width: { xs: '100%', sm: '100%' },
    //                 height: 38,
    //                 '.MuiAutocomplete-input': {
    //                   height: 8,
    //                   overflow: 'auto'
    //                 },
    //                 '.MuiAutocomplete-tag': {
    //                   margin: '2px'
    //                 }
    //               }}
    //               InputProps={{
    //                 ...params.InputProps,
    //                 sx: { height: '40px' }
    //               }}
    //               InputLabelProps={{
    //                 sx: { top: -6 }
    //               }}
    //             />
    //           )}
    //           sx={{
    //             flexGrow: 1,
    //             minWidth: '170px',
    //             width: { xs: '100%', sm: '100%' }
    //           }}
    //         />
    //         <Autocomplete
    //           options={allProducts}
    //           value={filter?.product?.value}
    //           onChange={(event, newValue) =>
    //             setFilter((prev) => ({ ...prev, product: newValue }))
    //           }
    //           getOptionLabel={(option) => option?.value || ''}
    //           renderInput={(params) => (
    //             <TextField
    //               {...params}
    //               label="Product"
    //               variant="outlined"
    //               fullWidth
    //               sx={{
    //                 flexGrow: 1,
    //                 minWidth: '150px',
    //                 width: { xs: '100%', sm: '100%' },
    //                 height: 38,
    //                 '.MuiAutocomplete-input': {
    //                   height: 8,
    //                   overflow: 'auto'
    //                 },
    //                 '.MuiAutocomplete-tag': {
    //                   margin: '2px'
    //                 }
    //               }}
    //               InputProps={{
    //                 ...params.InputProps,
    //                 sx: { height: '40px' }
    //               }}
    //               InputLabelProps={{
    //                 sx: { top: -6 }
    //               }}
    //             />
    //           )}
    //           sx={{
    //             flexGrow: 1,
    //             minWidth: '150px',
    //             width: { xs: '100%', sm: '100%' }
    //           }}
    //         />
    //         <Autocomplete
    //           options={allRole}
    //           value={filter?.addedBy?.value}
    //           onChange={(event, newValue) =>
    //             setFilter((prev) => ({ ...prev, addedBy: newValue?.value }))
    //           }
    //           getOptionLabel={(option) => option?.value || ''}
    //           renderInput={(params) => (
    //             <TextField
    //               {...params}
    //               label="Added By"
    //               variant="outlined"
    //               fullWidth
    //               sx={{
    //                 flexGrow: 1,
    //                 minWidth: '150px',
    //                 width: { xs: '100%', sm: '100%' },
    //                 height: 38,
    //                 '.MuiAutocomplete-input': {
    //                   height: 8,
    //                   overflow: 'auto'
    //                 },
    //                 '.MuiAutocomplete-tag': {
    //                   margin: '2px'
    //                 }
    //               }}
    //               InputProps={{
    //                 ...params.InputProps,
    //                 sx: { height: '40px' }
    //               }}
    //               InputLabelProps={{
    //                 sx: { top: -6 }
    //               }}
    //             />
    //           )}
    //           sx={{
    //             flexGrow: 1,
    //             minWidth: '150px',
    //             width: { xs: '100%', sm: '100%' }
    //           }}
    //         />
    //         <Button
    //           variant="contained"
    //           color="primary"
    //           onClick={handleSearch}
    //           startIcon={<SearchIcon />}
    //           sx={{
    //             display: { xs: 'flex', sm: 'none' },
    //             width: '100%'
    //           }}
    //         >
    //           Search
    //         </Button>
    //         <IconButton
    //           color="primary"
    //           onClick={handleSearch}
    //           aria-label="search"
    //           sx={{
    //             display: { xs: 'none', sm: 'flex' },
    //             bgcolor: 'primary.main',
    //             color: 'white',
    //             '&:hover': { bgcolor: 'primary.dark' }
    //           }}
    //         >
    //           <SearchIcon />
    //         </IconButton>
    //       </Box>
    //     </Grid>
    //   </Grid>

    //   <SalesDetailsModel
    //     detailsModalOpen={detailsModalOpen}
    //     detailsModalData={detailsModalData}
    //     handleViewClose={handleViewClose}
    //   />
    //   <SalesForm
    //     open={open}
    //     allClients={allClients}
    //     allProducts={allProducts}
    //     dropDownOptions={dropDownOptions}
    //     initialSale={initialSale}
    //     handleSale={editingSale ? handleUpdateSale : handleAddSale}
    //     handleClose={handleClose}
    //   />

    //   <SaleTable
    //     loading={loading}
    //     page={page}
    //     rowsPerPage={rowsPerPage}
    //     allSalesData={allSalesData}
    //     allClients={allClients}
    //     allProducts={allProducts}
    //     totalSales={totalSales}
    //     hasPermission={hasPermission}
    //     dropDownOptions={dropDownOptions}
    //     handleViewClick={handleViewClick}
    //     handleEdit={handleEdit}
    //     handleUpdateSaleField={handleUpdateSaleField}
    //     handleDeleteSale={handleDeleteSale}
    //     handleChangePage={handleChangePage}
    //     handleChangeRowsPerPage={handleChangeRowsPerPage}
    //   />
    // </Container>
    <Container>
      <Box className="breadcrumb">
        <Breadcrumb routeSegments={[{ name: 'Sales' }]} />
      </Box>
      <Grid container columnGap={1} rowGap={2}>
        {/* Left-aligned button */}
        {hasPermission('Sales.Orders:add') && (
          <Grid item xs={12} sm={12} md={3} lg={2}>
            <Button
              variant="contained"
              color="primary"
              onClick={() => handleClickOpen()}
              disabled={submitting}
            >
              + Add Sales
            </Button>
          </Grid>
        )}

        {/* Right-aligned filters */}
        <Grid xs={12} sm={12} md={3} lg={2}>
          <Autocomplete
            size="small"
            options={allClients}
            value={filter.customerName?.value}
            onChange={(event, newValue) =>
              setFilter((prev) => ({ ...prev, customerName: newValue }))
            }
            getOptionLabel={(option) => option?.value || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Name"
                variant="outlined"
                fullWidth
              />
            )}
            // sx={{ marginTop: '16px' }}
          />
        </Grid>
        <Grid item xs={12} sm={12} md={3} lg={2}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DesktopDatePicker
              onChange={(date) =>
                setFilter((prev) => ({ ...prev, date: date }))
              }
              format="DD-MM-YYYY"
              slotProps={{
                field: {
                  clearable: true,
                  onClear: () => setFilter((prev) => ({ ...prev, date: null }))
                },
                textField: {
                  sx: {
                    flexGrow: 1,
                    minWidth: '160px',

                    width: { xs: '100%', sm: '100%' },
                    '.css-14rz9al-MuiInputBase-root-MuiOutlinedInput-root': {
                      height: '36.8px',
                      overflow: 'hide'
                    }
                  }
                }
              }}
            />
          </LocalizationProvider>
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={2}>
          <Autocomplete
            size="small"
            options={allCompanyName}
            value={filter?.companyName}
            onChange={(event, newValue) =>
              setFilter((prev) => ({ ...prev, companyName: newValue }))
            }
            renderInput={(params) => (
              <TextField
                {...params}
                label="Company Name"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={1}>
          {' '}
          <Autocomplete
            size="small"
            options={allProducts}
            value={filter?.product?.value}
            onChange={(event, newValue) =>
              setFilter((prev) => ({ ...prev, product: newValue }))
            }
            getOptionLabel={(option) => option?.value || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Product"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={1}>
          <Autocomplete
            size="small"
            options={allRole}
            value={filter?.addedBy?.value}
            onChange={(event, newValue) =>
              setFilter((prev) => ({ ...prev, addedBy: newValue?.value }))
            }
            getOptionLabel={(option) => option?.value || ''}
            renderInput={(params) => (
              <TextField
                {...params}
                label="Added By"
                variant="outlined"
                fullWidth
              />
            )}
          />
        </Grid>

        <Grid item xs={12} sm={12} md={3} lg={1}>
          <Button
            variant="contained"
            color="primary"
            onClick={handleSearch}
            startIcon={<SearchIcon />}
            sx={{
              display: {
                xs: 'flex',
                sm: 'none'
              },
              width: '100%'
            }}
          >
            Search
          </Button>
          <IconButton
            color="primary"
            onClick={handleSearch}
            aria-label="search"
            sx={{
              display: { xs: 'none', sm: 'flex' },
              bgcolor: 'primary.main',
              color: 'white',
              '&:hover': { bgcolor: 'primary.dark' }
            }}
          >
            <SearchIcon />
          </IconButton>
        </Grid>
        {/* parent grid */}
      </Grid>

      <SalesDetailsModel
        detailsModalOpen={detailsModalOpen}
        detailsModalData={detailsModalData}
        handleViewClose={handleViewClose}
      />
      <SalesForm
        open={open}
        allClients={allClients}
        allProducts={allProducts}
        dropDownOptions={dropDownOptions}
        initialSale={initialSale}
        handleSale={editingSale ? handleUpdateSale : handleAddSale}
        handleClose={handleClose}
      />

      <SaleTable
        loading={loading}
        page={page}
        rowsPerPage={rowsPerPage}
        allSalesData={allSalesData}
        allClients={allClients}
        allProducts={allProducts}
        totalSales={totalSales}
        hasPermission={hasPermission}
        dropDownOptions={dropDownOptions}
        handleViewClick={handleViewClick}
        handleEdit={handleEdit}
        handleUpdateSaleField={handleUpdateSaleField}
        handleDeleteSale={handleDeleteSale}
        handleChangePage={handleChangePage}
        handleChangeRowsPerPage={handleChangeRowsPerPage}
      />
    </Container>
  )
}

export default SalesList
