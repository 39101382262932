import React, { useEffect, useState } from 'react'
import * as Yup from 'yup'
import {
  Dialog,
  DialogTitle,
  DialogActions,
  DialogContent,
  DialogContentText,
  Button,
  MenuItem,
  CircularProgress,
  FormControl,
  TextField,
  InputLabel,
  Select,
  Box
} from '@mui/material'
import { Formik, Form, ErrorMessage } from 'formik'

import InputTextField from '../InputFields/InputTextField'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { styled } from '@mui/material/styles'
import dayjs from 'dayjs'
import { useSnackbar } from 'app/snakebarContext'
import { HandleError } from 'app/utils/handleError'
import { useNavigate } from 'react-router-dom'
import AuthContext from 'app/contexts/JWTAuthContext'
import { useContext } from 'react'

const StyledDialog = styled(Dialog)(({ theme }) => ({
  '& .MuiDialogContent-root': {
    padding: theme.spacing(2),
    overflowY: 'auto',
    '&::-webkit-scrollbar': {
      width: '8px'
    },
    '&::-webkit-scrollbar-track': {
      background: '#f1f1f1',
      borderRadius: '10px'
    },
    '&::-webkit-scrollbar-thumb': {
      background: '#888',
      borderRadius: '10px',
      '&:hover': {
        background: '#555'
      }
    }
  }
}))

// Sales Validation Schema
const saleValidationSchema = Yup.object({
  customerName: Yup.string()
    .min(3, 'Customer name must be at least 3 characters long.')
    .max(30, 'Customer name must be less than or equal to 30 characters long.')
    .required('Customer name is required.'),

  tankerNo: Yup.string().required('Tanker number is required.'),

  invoiceNo: Yup.string().required('Invoice number is required.'),

  companyName: Yup.string()
    .min(3, 'Company name must be at least 3 characters long.')
    .max(100, 'Company name must be less than or equal to 100 characters long.')
    .required('Company name is required.'),

  product: Yup.string().required('Product is required.'),

  state: Yup.string().required('State is required.'),
  city: Yup.string().required('City is required.'),
  source: Yup.string()
    .oneOf(
      [
        'IndiaMART',
        'TradeIndia',
        'Facebook',
        'Whatsapp',
        'Instagram',
        'References'
      ],
      'Source must be one of the following: IndiaMART, TradeIndia, Facebook, Whatsapp, Instagram, References.'
    )
    .required('Source is required.'),

  quantity: Yup.number()
    .min(1, 'Quantity must be at least 1.')
    .required('Quantity is required.'),

  unit: Yup.string()
    .oneOf(['KL', 'MTS'], 'Unit must be one of MTS, KL.')
    .required('Unit is required.'),

  date: Yup.date()
    .required('Date is required.')
    .typeError('Date must be a valid date.'),

  density: Yup.number()
    .required('Density is required.')
    .positive('Density must be a positive number.')
    .typeError('Density must be a number.')
    .min(0, 'Density must be at least 0.')
    .max(999, 'Density must be at most 999.'),

  fullRate: Yup.number()
    .required('Full Rate is required.')
    .positive('Full Rate must be a positive number.')
    .typeError('Full Rate must be a number.'),

  payment: Yup.string()
    .oneOf(['Paid', 'Unpaid'], 'Payment must be either Paid or Unpaid.')
    .required('Payment is required.'),

  paidAmount: Yup.number()
    .required('Paid Amount is required.')
    .positive('Paid Amount must be a positive number.')
    .typeError('Paid Amount must be a number.')
})

const SalesForm = ({
  open,
  allClients,
  allProducts,
  dropDownOptions,
  initialSale,
  handleSale,
  handleClose
}) => {
  const navigate = useNavigate()
  const { showSnackbar } = useSnackbar()
  const { states, stateCityMap } = useContext(AuthContext)
  const [localCities, setLocalCities] = useState([])

  // Effect to set cities when state changes
  useEffect(() => {
    if (initialSale) {
      const initialState = initialSale.state
      setLocalCities(stateCityMap[initialState] || [])
    }
  }, [initialSale, stateCityMap])

  return (
    <StyledDialog
      open={open}
      onClose={handleClose}
      aria-labelledby="form-dialog-title"
      fullWidth
    >
      <DialogTitle id="form-dialog-title">
        {initialSale ? 'Edit Sale' : 'Add Sale'}
      </DialogTitle>

      <Formik
        initialValues={{
          customerName: initialSale ? initialSale?.customerName?._id : '',
          tankerNo: initialSale ? initialSale?.tankerNo : '',
          invoiceNo: initialSale ? initialSale?.invoiceNo : '',
          companyName: initialSale ? initialSale?.companyName : '',
          product: initialSale ? initialSale.product?._id : '',
          state: initialSale ? initialSale.state : '',
          city: initialSale ? initialSale.city : '',
          source: initialSale ? initialSale?.source : '',
          quantity: initialSale ? initialSale?.quantity : '',
          unit: initialSale ? initialSale?.unit : '',
          date: initialSale ? dayjs(initialSale?.date) : null,
          density: initialSale ? initialSale?.density : '',
          fullRate: initialSale ? initialSale?.fullRate : '',
          payment: initialSale ? initialSale?.payment : '',
          paidAmount: initialSale ? initialSale?.paidAmount : ''
        }}
        validationSchema={saleValidationSchema}
        onSubmit={async (values, { setSubmitting, resetForm }) => {
          try {
            setSubmitting(true)
            await handleSale(values)
            handleClose()
            resetForm()
          } catch (error) {
            HandleError(error, showSnackbar, navigate)
            setSubmitting(false)
          } finally {
            setSubmitting(false)
          }
        }}
      >
        {({
          isSubmitting,
          handleChange,
          handleBlur,
          setFieldValue,
          values,
          errors,
          touched
        }) => (
          <Form>
            <DialogContent>
              <DialogContentText>
                {initialSale
                  ? 'Edit the Task details below.'
                  : 'Please fill out the form to add a new sale.'}
              </DialogContentText>

              {/* form fields */}

              <FormControl fullWidth margin="normal">
                <InputLabel id="customerName-label">Customer Name</InputLabel>
                <Select
                  name="customerName"
                  labelId="customerName-label"
                  label="Customer Name"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values?.customerName}
                  error={Boolean(errors.customerName && touched.customerName)}
                >
                  {allClients.length > 0 ? (
                    allClients.map((client) => (
                      <MenuItem value={client?.key} key={client?.key}>
                        {client?.value}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem disabled>No options</MenuItem>
                  )}
                </Select>
                <ErrorMessage
                  name="customerName"
                  component="div"
                  style={{
                    color: '#FF3D57',
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 1.66,
                    letterSpacing: '0.03333em',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginRight: '14px',
                    marginBottom: '0',
                    marginLeft: '14px'
                  }}
                />
              </FormControl>
              <InputTextField
                id="tankerNo"
                name="tankerNo"
                label="Tanker No"
                margin="dense"
                formik={{ values, handleChange, handleBlur, errors, touched }}
              />
              <InputTextField
                id="invoiceNo"
                name="invoiceNo"
                label="Invoice No"
                margin="dense"
                formik={{ values, handleChange, handleBlur, errors, touched }}
              />
              <InputTextField
                id="companyName"
                name="companyName"
                label="Company Name"
                margin="dense"
                formik={{ values, handleChange, handleBlur, errors, touched }}
              />

              <FormControl fullWidth margin="dense">
                <InputLabel id="product-label">Source</InputLabel>
                <Select
                  name="source"
                  labelId="source-label"
                  label="Source"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.source}
                  error={Boolean(errors.source && touched.source)}
                >
                  {dropDownOptions.sourceDropdownOptions.map((source) => (
                    <MenuItem value={source} key={source}>
                      {source}
                    </MenuItem>
                  ))}
                </Select>
                <ErrorMessage
                  name="product"
                  component="div"
                  style={{
                    color: '#FF3D57',
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 1.66,
                    letterSpacing: '0.03333em',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginRight: '14px',
                    marginBottom: '0',
                    marginLeft: '14px'
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="dense">
                <InputLabel id="product-label">Product</InputLabel>
                <Select
                  name="product"
                  labelId="product-label"
                  label="Product"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.product}
                  error={Boolean(errors.product && touched.product)}
                >
                  {allProducts.map((product) => (
                    <MenuItem value={product.key} key={product.key}>
                      {product.value}
                    </MenuItem>
                  ))}
                </Select>
                <ErrorMessage
                  name="product"
                  component="div"
                  style={{
                    color: '#FF3D57',
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 1.66,
                    letterSpacing: '0.03333em',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginRight: '14px',
                    marginBottom: '0',
                    marginLeft: '14px'
                  }}
                />
              </FormControl>

              <FormControl fullWidth margin="dense">
                <InputLabel id="state-label">State</InputLabel>
                <Select
                  name="state"
                  labelId="state-label"
                  label="State"
                  onChange={(e) => {
                    const selectedState = e.target.value
                    setFieldValue('state', selectedState)
                    setFieldValue('city', '')
                    setLocalCities(stateCityMap[selectedState] || [])
                  }}
                  onBlur={handleBlur}
                  value={values.state}
                  error={Boolean(errors.state && touched.state)}
                >
                  {states.map((state) => (
                    <MenuItem value={state} key={state}>
                      {state}
                    </MenuItem>
                  ))}
                </Select>
                <ErrorMessage
                  name="state"
                  component="div"
                  style={{
                    color: '#FF3D57',
                    fontWeight: 400,
                    fontSize: '0.75rem',
                    lineHeight: 1.66,
                    letterSpacing: '0.03333em',
                    textAlign: 'left',
                    marginTop: '3px',
                    marginRight: '14px',
                    marginBottom: '0',
                    marginLeft: '14px'
                  }}
                />
              </FormControl>
              <FormControl fullWidth margin="dense">
                <InputLabel id="city-label">City</InputLabel>
                <Select
                  name="city"
                  labelId="city-label"
                  label="City"
                  onChange={handleChange}
                  onBlur={handleBlur}
                  value={values.city}
                  error={Boolean(errors.city && touched.city)}
                >
                  {localCities.length > 0 ? (
                    localCities.map((city) => (
                      <MenuItem key={city} value={city}>
                        {city}
                      </MenuItem>
                    ))
                  ) : (
                    <MenuItem value="">
                      <em>No City Available</em>
                    </MenuItem>
                  )}
                </Select>
                {touched.city && errors.city && (
                  <ErrorMessage
                    name="city"
                    component="div"
                    style={{
                      color: '#FF3D57',
                      fontWeight: 400,
                      fontSize: '0.75rem',
                      lineHeight: 1.66,
                      letterSpacing: '0.03333em',
                      textAlign: 'left',
                      marginTop: '3px',
                      marginRight: '14px',
                      marginBottom: '0',
                      marginLeft: '14px'
                    }}
                  />
                )}
              </FormControl>
              <Box
                component="form"
                display="flex"
                justifyContent="space-between"
                gap={2} // adds space between the fields
                flexDirection={{ xs: 'column', sm: 'row' }} // Stack vertically on small screens, horizontally on larger screens
                sx={{
                  '& > *': {
                    flex: 1,
                    minWidth: '0',
                    width: { xs: '100%', sm: '48%' } // 100% width on small screens, 48% on larger screens
                  },
                  margin: '12px auto 15px auto'
                }}
              >
                <InputTextField
                  id="quantity"
                  name="quantity"
                  label="Quantity"
                  type="number"
                  margin="0 "
                  formik={{ values, handleChange, handleBlur, errors, touched }}
                />

                <FormControl margin="normal" sx={{ width: '100%', margin: 0 }}>
                  <InputLabel id="unit-label">Unit</InputLabel>
                  <Select
                    name="unit"
                    labelId="unit-label"
                    label="Unit"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.unit}
                    error={Boolean(errors.unit && touched.unit)}
                    sx={{ width: '100%' }}
                  >
                    {dropDownOptions.unitDropdownOptions.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    name="unit"
                    component="div"
                    style={{
                      color: '#FF3D57',
                      fontWeight: 400,
                      fontSize: '0.75rem',
                      lineHeight: 1.66,
                      letterSpacing: '0.03333em',
                      textAlign: 'left',
                      marginTop: '3px',
                      marginRight: '14px',
                      marginBottom: '0',
                      marginLeft: '14px'
                    }}
                  />
                </FormControl>
              </Box>
              <Box
                component="form"
                display="flex"
                justifyContent="space-between"
                gap={2} // adds space between the fields
                flexDirection={{ xs: 'column', sm: 'row' }} // Stack vertically on small screens, horizontally on larger screens
                sx={{
                  '& > *': {
                    flex: 1,
                    minWidth: '0',
                    width: { xs: '100%', sm: '48%' } // 100% width on small screens, 48% on larger screens
                  },
                  margin: '10px auto 0px auto'
                }}
              >
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <Box sx={{ width: '100%' }}>
                    <DatePicker
                      label="Date"
                      format="DD/MM/YYYY"
                      value={values.date}
                      onChange={(date) => setFieldValue('date', date)}
                      renderInput={(params) => (
                        <TextField
                          {...params}
                          margin="dense"
                          fullWidth
                          error={Boolean(errors.date && touched.date)}
                          helperText={
                            errors.date && touched.date ? errors.date : ''
                          }
                        />
                      )}
                      sx={{ width: '100%' }}
                    />
                    <ErrorMessage
                      name="date"
                      component="div"
                      style={{
                        color: '#FF3D57',
                        fontWeight: 400,
                        fontSize: '0.75rem',
                        lineHeight: 1.66,
                        letterSpacing: '0.03333em',
                        textAlign: 'left',
                        marginTop: '3px',
                        marginRight: '14px',
                        marginBottom: '0',
                        marginLeft: '14px'
                      }}
                    />
                  </Box>
                </LocalizationProvider>

                <InputTextField
                  id="density"
                  name="density"
                  label="Density"
                  margin="0"
                  formik={{ values, handleChange, handleBlur, errors, touched }}
                />
              </Box>
              <Box
                component="form"
                display="flex"
                justifyContent="space-between"
                gap={2} // adds space between the fields
                flexDirection={{ xs: 'column', sm: 'row' }} // Stack vertically on small screens, horizontally on larger screens
                sx={{
                  '& > *': {
                    flex: 1,
                    minWidth: '0',
                    width: { xs: '100%', sm: '48%' } // 100% width on small screens, 48% on larger screens
                  },
                  margin: '12px auto 15px auto'
                }}
              >
                <InputTextField
                  id="fullRate"
                  name="fullRate"
                  label="Full Rate (+18%)"
                  type="number"
                  margin="0 "
                  formik={{ values, handleChange, handleBlur, errors, touched }}
                />

                <FormControl margin="normal" sx={{ width: '100%', margin: 0 }}>
                  <InputLabel id="payment-label">Payment</InputLabel>
                  <Select
                    name="payment"
                    labelId="payment-label"
                    label="Payment"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    value={values.payment}
                    error={Boolean(errors.payment && touched.payment)}
                    sx={{ width: '100%' }}
                  >
                    {dropDownOptions.paymentDropdownOptions.map((option) => (
                      <MenuItem value={option} key={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                  <ErrorMessage
                    name="payment"
                    component="div"
                    style={{
                      color: '#FF3D57',
                      fontWeight: 400,
                      fontSize: '0.75rem',
                      lineHeight: 1.66,
                      letterSpacing: '0.03333em',
                      textAlign: 'left',
                      marginTop: '3px',
                      marginRight: '14px',
                      marginBottom: '0',
                      marginLeft: '14px'
                    }}
                  />
                </FormControl>
              </Box>

              <InputTextField
                id="paidAmount"
                name="paidAmount"
                label="Paid Amount"
                type="number"
                margin="0 "
                formik={{ values, handleChange, handleBlur, errors, touched }}
              />
            </DialogContent>

            <DialogActions>
              <Button
                onClick={handleClose}
                variant="contained"
                color="secondary"
              >
                Cancel
              </Button>
              <Button
                type="submit"
                variant="contained"
                color="primary"
                disabled={isSubmitting}
              >
                {isSubmitting ? (
                  <CircularProgress size={18} />
                ) : initialSale ? (
                  'Save Changes'
                ) : (
                  'Save'
                )}
              </Button>
            </DialogActions>
          </Form>
        )}
      </Formik>
    </StyledDialog>
  )
}

export default SalesForm
